import { render, staticRenderFns } from "./DescriptionSection.vue?vue&type=template&id=340ed352&scoped=true"
import script from "./DescriptionSection.vue?vue&type=script&lang=js"
export * from "./DescriptionSection.vue?vue&type=script&lang=js"
import style0 from "./DescriptionSection.vue?vue&type=style&index=0&id=340ed352&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340ed352",
  null
  
)

export default component.exports