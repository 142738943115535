<template>
  <base-section>
    <base-section-heading :title="title">{{ content }}</base-section-heading>
    <div class="video-container">
      <video :key="videoSrc" controls width="800">
        <source :src="videoSrc" type="video/mp4">
        Tu navegador no soporta el video.
      </video>
    </div>
  </base-section>
    
</template>

<style scoped>
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px;
}

video {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

</style>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        content: {
            type: String,
            default: 'Content'
        },
    },

    computed: {
      videoSrc() {
        return this.$t("home.description.video");
      },
    },
};
</script>
